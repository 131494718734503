<template>
  <v-card data-cy="contract-card">
    <v-list-item>
      <v-list-item-content>
        <template v-if="contract.type === typeProduct">
          <v-container
            fluid
            class="pa-0"
          >
            <core-element-product-info :contract="contract">
              <v-btn
                rounded
                depressed
                color="primary"
                class="d-block px-3 contract-button"
                :to="{
                  name: productDetailsRoute,
                  params: { contractId: contract.id },
                }"
                :data-cy-contract-button="true"
                :data-cy-contract-id="contract.id"
              >
                <span class="text-truncate">
                  {{ $t('contractCard.linkToProductDetails') }}
                </span>
              </v-btn>
            </core-element-product-info>
          </v-container>
        </template>

        <template v-else>
          <core-element-service-info :contract="contract" />
        </template>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { TYPE_PRODUCT } from '../../../../constants/app'
import { ROUTE_PRODUCT_DETAILS } from '../../../../constants/routeNames'

export default {
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      productDetailsRoute: ROUTE_PRODUCT_DETAILS,
      typeProduct: TYPE_PRODUCT
    }
  }
}
</script>

<style lang="scss">
.v-btn.v-size--default.contract-button {
  padding-top: 10px;
  text-align: center;
  max-width: 100%;
}
</style>
